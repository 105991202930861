.loadingData{
  justify-content: center;
}
.about_content{
	font-size: 16px !important;
	padding: 20px !important;
}

.about_image{
    height: 200px !important;
    margin: 14px !important;
    float: left !important;
}
.about_desc{
    font-size: 16px;
}